<template>
	<div class="detailPage">
		<el-form :model="dataForm" :rules="dataRule" ref="formData" @keyup.enter.native="dataFormSubmit()" label-width="180px">
			<el-form-item label="名称" prop="name">
				<el-input v-model="dataForm.name" placeholder="名称"></el-input>
			</el-form-item>
			<el-form-item
				label="医生提现代扣税配置"
				:rules="{
					required: true, message: '提现代扣税不能为空', trigger: 'blur'
				}"
			>
				<template v-if="dataForm.taxs.length">
					<div
						v-for="(tax, index) in dataForm.taxs"
						:key="index"
						style="margin-top: 10px;"
					>
						计税金额：<el-input v-model="tax.name" style="width: 400px; margin-right: 10px;" clearable></el-input>
						代扣税税率：<el-input v-model="tax.taxRate" style="width: 400px; margin-right: 10px;" clearable></el-input>
						<el-button v-if="index === dataForm.taxs.length - 1" @click.prevent="addTax">添加</el-button>
						<el-button v-if="dataForm.taxs.length > 1" @click.prevent="removeTax(index)">删除</el-button>
					</div>
				</template>
			</el-form-item>
			<el-form-item class="buttons">
				<el-button type="primary" @click="dataFormSubmit()">确认提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
export default {
	data () {
		return {
			dataForm: {
				name: '',
				taxs: [
					{
						name: '',
						taxRate: ''
					}
				]
			},
			dataRule: {
				name: [
					{
						required: true,
						message: '菜单名称不能为空',
						trigger: 'blur'
					}
				]
			}
		};
	},
	methods: {
		dataFormSubmit () {
			this.$root.request('cashoutTaxOpt', {
				name: this.dataForm.name,
				cashOutTaxConfig: this.dataForm.taxs
			}).then((data) => {
				if (data.ok === 1) {
					this.$router.go(-1);
				}
			});
		},
		addTax () {
			this.dataForm.taxs.push({
				name: '',
				taxRate: ''
			});
		},
		removeTax (index) {
			this.dataForm.taxs.splice(index, 1);
		}
	}
};
</script>